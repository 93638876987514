body {
  margin: 0;
  font-family: 'system-ui', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App {
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  height: 100vh;
  background-color: black;
}

nav {
  background-color: #212020;
  padding: 10px;
  height: 32px;
}

nav .logo {
  font-family: cursive;
  font-weight: bolder;
}

.App ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.App li {
  margin-left: 10px;
  margin-bottom: 10px;
}

.App a {
  text-decoration: none;
  color: #ccc;
  font-size: 14px;
  font-weight: 100;
}

.App a:hover {
  color: white;
}

.App .project-sub-container,
.App .About-sub-container {
  display: none;
  position: absolute;
  opacity: 98%;
  border-radius: 0 0 10px 10px;
  background-color: #212020;
  transform: translateY(24px);
  transition: transform 0.3s ease; 
}


.App .About-sub-container {
  width: 40vh;
  height: 15vh;
  margin-left: -100px;

}

.App .project-sub-container {
  width: 100vh;
  height: 80vh;
  margin-left: -260px;
}

.App .about:hover .About-sub-container {
  display: flex;
  flex-flow: row wrap;
  transform: translateY(0);
}

.App .project:hover .project-sub-container {
  display: flex;
  flex-flow: row wrap;
  transform: translateY(0);
}

.About-sub-container .about-list {
  display: flex;
  margin-top: 20px;
  flex-flow: column wrap;
}

.App .project-sub-container .project-tech {
  display: flex;
  flex-direction: column;
  color: grey;
  justify-content: space-evenly;
}

@media (max-width: 767px) {
  nav .logo {
    margin-right: 0;
    margin-left: 0;
  }

  .App ul {
    flex-direction: column;
    align-items: center;
  }

  .App li {
    margin-left: 0;
    margin-bottom: 8px;
  }

  .App a {
    font-size: 14px;
  }
}
