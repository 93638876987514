
  
.intro {
    width: 100%;
    max-width: 650px;
    color: #b9d6f2;
    font-size: 30pt;
    text-align: center;
    margin-bottom: 50px;
}

.intro-title{
  color: #0353a4;
}
@media screen and (max-width: 767px) {
  .intro {
    font-size: 24pt;
  }
}
