.Project {
    min-width:100vh;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
    scroll-behavior: auto;
    background-color: #f5f5f7;
  }
  
  .project-section {
    margin-bottom: 40px;
  }
  
  .tech-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .project-article {
    border: 1px solid #ddd;
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .project-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .tech-stack {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .project-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    margin-top: 10px;
  }
  .project-img{
    height: 300px;
    width: 600px;
    border-color: black;
  }
  .project-img:hover{
    opacity: 80%;
  }
  .project-link:hover {
    color: #2574c9;
  }
  