.About{
    background-color: black;
}
.About h1 {
    font-size: 24px;
    margin-bottom: 15px;
}

.Timeline {
    display: flex;
    flex-direction: column;
}

.Timeline .TimelineItem {
    border-left: 2px solid #3498db;
    margin-bottom: 20px;
    padding: 0 20px; /* Adjusted to apply padding to all sides */
}

.Timeline h3 {
    font-size: 18px;
    margin-bottom: 5px;
}

.Timeline p {
    font-size: 14px;
    margin-bottom: 10px;
}

.Timeline ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-top: 0; /* Reset the top margin for lists */
}

.Timeline li {
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 1.4;
    white-space: pre-line; /* Add this line to preserve new lines in the content */
}

hr {
    border: 1px solid #8d8787; /* Adjust the color and style as needed */
    margin: 10px 0; /* Adjust margin if needed */
}
