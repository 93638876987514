#Skills {
    padding: 20px;
    text-align: center;
  }
  
  h2 {
    color: #333;
  }
  
  .skills_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  #skill_img {
    width: 100px;
    height: auto;
    margin: 10px;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
  }
  
  #skill_img:hover {
    filter: grayscale(0%);
  }
  