/* Contact.css */
#Contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
  }
  
  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: #F5F5F5;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .social-logo {
    transition: color 0.3s ease;
  }
  
  .icon-circle:hover {
    transform: scale(1.1);
  }
  
  .github:hover {
    background-color: #333;
  }
  
  .linkedin:hover {
    background-color: #0072b1;
  }
  
  .facebook:hover {
    background-color: #4267B2;
  }
  
  .instagram:hover {
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  }
  
  .mail:hover {
    background-color: #E8F0FE;
  }
  
  .mail:hover > .mail-logo {
    color: #cd233d;
  }
  
  /* styles for screens smaller than 768px */
  @media only screen and (max-width: 767px) {
    #Contact {
      padding: 40px 0;
    }
    .icons {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .icon-circle {
      width: 50px;
      height: 50px;
    }
    .social-logo {
      width: 20px;
      height: 20px;
    }
  }
  